<template>
  <v-card v-bind="$attrs" class="v-card--material mt-4">
    <v-card-title class="align-start">
      <v-sheet
        :color="color"
        :width="fullHeader ? '100%' : undefined"
        class="overflow-hidden mt-n9 transition-swing v-card--material__sheet"
        elevation="6"
        max-width="100%"
        rounded
      >
        <v-theme-provider v-if="hasHeading" dark>
          <div v-if="icon" :class="iconSmall ? 'pa-7' : 'pa-8'">
            <v-icon :large="!iconSmall" v-text="icon" />
          </div>

          <slot name="heading" />

          <div
            v-if="heading"
            class="text-h6 white--text pa-7 v-card--material__title"
          >
            {{ heading }}
          </div>
        </v-theme-provider>
      </v-sheet>

      <div
        v-if="hasTitle"
        :class="fullHeader ? 'pt-4' : 'pl-3'"
        class="text-h5 v-card--material__title"
      >
        <slot name="title" />

        <template v-if="ttitle">
          <v-tooltip bottom :disabled="!tooltip">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="truncate-text title">
                {{ ttitle }}</span
              ></template
            >
            <span>{{ tooltip }}</span>
          </v-tooltip>
        </template>

        <div class="text-subtitle-1  mb-n4">
          <slot name="subtitle" />

          <template v-if="subtitle">
            <div class="title text--secondary font-weight-bold">
              <div v-html="subtitle"></div></div
          ></template>
        </div>
      </div>
    </v-card-title>

    <slot />

    <template v-if="$slots.actions">
      <v-divider class="mt-2 mx-4" />

      <v-card-actions class="px-4 text-caption grey--text">
        <slot name="actions" />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
export default {
  name: "DashboardCard",

  props: {
    color: String,
    fullHeader: Boolean,
    heading: String,
    icon: String,
    iconSmall: Boolean,
    subtitle: [String, Number],
    tooltip: String,
    ttitle: String
  },

  computed: {
    hasHeading() {
      return !!(this.icon || this.heading || this.$slots.heading);
    },
    hasTitle() {
      return !!(
        this.ttitle ||
        this.subtitle ||
        this.$slots.title ||
        this.$slots.subtitle
      );
    }
  }
};
</script>

<style lang="sass">
.v-card.v-card--material
  > .v-card__title
    > .v-card--material__title
      flex: 1 1 auto
      word-break: break-word
</style>
